import React from 'react';
import '../../../Font.css';
import './ObjectExistingSystem.css';

class ObjectExistingSystem extends React.Component {

    constructor(props) {
        super(props);

        this.handleClickObject = this.handleClickObject.bind(this);
    }

    render() {

        var detailSystem = "";
        var imgBlock =  "";

        var isV2 = false;

        if (!this.props.displayChooseCompare && isV2) {
            detailSystem = <div className="detailsSystem">
                <div className="btnDescribe"onClick={() => this.props.toggleDescribe(this.props.system)}>Describe</div>
                <div className="btnCompare" onClick={this.props.toggleDisplayChooseCompare}>Compare</div>
            </div>;
        } else if (!this.props.displayChooseCompare) {
            detailSystem = <div className="detailsSystem">
                <div className="btnDescribe describeCenter"onClick={() => this.props.toggleDescribe(this.props.system)}>Describe</div>
            </div>;
        }

            imgBlock =  <img className="imgSystem" src={"http://molecularhci.imag.fr/img/" + this.props.system.imgPath + ".png"} alt={"Picture of " + this.props.system.name}/>


        return (
            <div className="WrapperSystem">
                <div className={"paperDomain " + this.props.system.domain}>{this.props.system.year + " - " +(this.props.system.domain === "HCI" ? "Human-Computer Interaction" : "Robotics")}</div>

                {imgBlock}
                {detailSystem}

                <div className={"chooseCompare " + (this.props.displayChooseCompare ? " active" : "")}>
                    <div className="btnChooseCompare" onClick={this.props.toggleCompare}>With this one</div>
                </div>

                <div className="WrapperDetails" onClick={this.handleClickObject}>
                    <div className="paperTitle">{this.props.system.title}</div>
                    <div className="paperAuthors">{this.props.system.authors}</div>
                    <div className="paperProceedings">{this.props.system.proceedings}</div>
                </div>
            </div>
        );
    }

    handleClickObject () {
        window.open('https://doi.org/' + this.props.system.doi);
    }
}

export default ObjectExistingSystem;
