import React from 'react';
import '../../../Font.css';
import './ObjectRequirement.css';

class ObjectRequirement extends React.Component {

    constructor(props) {
        super(props);

        this.toggleActive = this.toggleActive.bind(this);

        this.state = {isActive: false};
    }

    toggleActive() {
       let actualValue = this.state.isActive;
       this.setState({isActive: !actualValue});
    }

    render() {
        var dropdownRequirement = "";

       if (this.state.isActive) {
            dropdownRequirement = <div className="dropdownDescription visible">{this.props.descriptionRequirement}</div>
        } else {
           dropdownRequirement = <div className="dropdownDescription hidden" />
       }

        return (
            <div className="ObjectRequirementWrapper">
                <div className="ObjectRequirementMin" onClick={this.toggleActive}>
                    <div className="levelRequirement">{this.props.levelRequirement}</div>
                    <div className="idRequirementSeparator"/>
                    <div className="titleRequirement">{this.props.titleRequirement}</div>

                </div>
                {dropdownRequirement}
            </div>
        );
    }

}

export default ObjectRequirement;