import ActiveFilterModel from './ActiveFilterModel';

class FilterModel {

    constructor() {
        /* Architecture and reconfiguration */
        this.architecture = [];
        this.reconfiguration = [];

        /* Macro properties */
        this.dimensionality = [];
        this.shapeChange = [];
        this.isReversible = [];
        this.actuation = [];
        this.isCombinationPhysicalVirtual = [];
        this.surfaceResolution = [];
        this.hierarchy = [];
        this.isMultistable = false;
        this.isInteractive = [];
        this.isSafe = false;
        this.isSpaceConstrained = false;

        this.activeFilters = [];

        this.updateFilters = this.updateFilters.bind(this);
    }

    delete(id) {
        switch (id) {
            case "Architecture": this.architecture = [];
            break;
            case "Dimensionality": this.dimensionality = [];
            break;
            case "Shape-change ability": this.shapeChange = [];
            break;
            case "Reversibility": this.isReversible = [];
            break;
            case "Control over shape-change": this.actuation = [];
            break;
            case "Combination between physical and digital representation(s)" : this.isCombinationPhysicalVirtual = [];
            break;
            case "Surface and resolution" : this.surfaceResolution = [];
            break;
            case "Hierarchy" : this.hierarchy = [];
            break;
            case "Energy consumption" : this.isMultistable = false;
            break;
            case "Interactivity" : this.isInteractive = [];
            break;
            case "Safety" : this.isSafe = false;
            break;
            case "Space required for shape-change" : this.isSpaceConstrained = false;
            break;
            default:
                break;
        }

        this.updateActiveFilters();
    }

    updateActiveFilters () {

        this.activeFilters = [];

        if (this.architecture.length != 0) {
            this.activeFilters.push("Architecture");
        }

        if (this.dimensionality.length != 0){
            this.activeFilters.push("Dimensionality");
        }

        if (this.shapeChange.length != 0) {
            this.activeFilters.push("Shape-change ability");
        }

        if (this.isReversible.length != 0) {
            this.activeFilters.push("Reversibility");
        }

        if (this.actuation.length != 0) {
            this.activeFilters.push("Control over shape-change");
        }

        if (this.isCombinationPhysicalVirtual.length != 0) {
            this.activeFilters.push("Combination between physical and digital representation(s)");
        }

        if (this.surfaceResolution.length != 0) {
            this.activeFilters.push("Surface and resolution");
        }

        if (this.hierarchy.length != 0) {
            this.activeFilters.push("Hierarchy");
        }

        if (this.isMultistable) {
            this.activeFilters.push("Energy consumption");
        }

        if (this.isInteractive.length != 0) {
            this.activeFilters.push("Interactivity");
        }

        if (this.isSafe) {
            this.activeFilters.push("Safety");
        }

        if (this.isSpaceConstrained) {
            this.activeFilters.push("Space required for shape-change");
        }

    }

   updateFilters (architecture,
                  dimensionality, shapeChange, isReversible,actuation,isCombinationPhysicalVirtual,surfaceResolution,hierarchy,isMultistable,isInteractive,isSafe,isSpaceConstrained) {
        this.architecture = architecture;
        this.dimensionality = dimensionality;
       this.shapeChange = shapeChange;
       this.isReversible = isReversible;
       this.actuation = actuation;
       this.isCombinationPhysicalVirtual = isCombinationPhysicalVirtual;
       this.hierarchy = hierarchy;
       this.surfaceResolution = surfaceResolution;
       this.isMultistable = isMultistable;
       this.isInteractive = isInteractive;
       this.isSafe = isSafe;
       this.isSpaceConstrained = isSpaceConstrained;

       this.updateActiveFilters();
   }

}

export default FilterModel;
