import React from 'react';
import '../../Font.css';
import './ExistingSystems.css';
import ObjectExistingSystem from "../ExistingSystems/ObjectExistingSystem/ObjectExistingSystem";
import Filters from './Filters/Filters';
import Describe from "./Describe/Describe";
import Compare from "./Compare/Compare";


class ExistingSystems extends React.Component {

    /*
    * systems: instance of SystemsListModel. All systems stored in the DB (systems.allSystems) + the filtered ones to display (systems.filteredSystems).
    *  filters: instance of FilterModel. Which filters are active, and there parameters.
    */
    constructor(props) {
        super(props);

        this.state = {
            displayDescribe: false,
            systemToDescribe: null,
            displayCompare: false,
            displayChooseCompare: false

        }

        this.toggleCompare = this.toggleCompare.bind(this);
        this.toggleDescribe = this.toggleDescribe.bind(this);
        this.toggleDisplayChooseCompare = this.toggleDisplayChooseCompare.bind(this);
    }


    toggleDescribe(system) {
        this.setState({displayDescribe: !this.state.displayDescribe, systemToDescribe: system});
    }

    toggleCompare() {
        this.setState({
            displayCompare: !this.state.displayCompare,
            displayChooseCompare: false
        });
    }

    toggleDisplayChooseCompare () {
        this.setState({displayChooseCompare: !this.state.displayChooseCompare});
    }

    render() {

        var describe = "";
        var compare = "";
        var filters = "";

        var isV2 = false;

        if (this.state.displayDescribe) {
            describe = <Describe
                system={this.state.systemToDescribe}
                toggleDescribe={this.toggleDescribe}/>;
        }

        if (this.state.displayCompare) {
            compare = <Compare
            toggleCompare={this.toggleCompare}/>;
        }


        if (isV2) {
            filters = <Filters filterSystems={this.props.filterSystems}
                         filters={this.props.filters}
                         filterList={this.props.filterList}
                         deleteFilter={this.props.deleteFilter}
                         navigateTo={this.props.navigateTo}
                />
        }

        return (
            <div className="ExistingSystemWrapper">
                <div className="PageTitle">Existing implementations of modular shape-changing interfaces</div>
                <div className="TitleSeparator"></div>


                {filters}

                {describe}
                {compare}

                <div className="SystemListWrapper">
                    <div className={"SystemList"}>
                        {this.props.systems.filteredSystems.map((system, i) => {
                            return (
                                <ObjectExistingSystem
                                    key={system.id}
                                    system={system}
                                    toggleDescribe={this.toggleDescribe}
                                    toggleCompare={this.toggleCompare}
                                    toggleDisplayChooseCompare={this.toggleDisplayChooseCompare}
                                    displayChooseCompare={this.state.displayChooseCompare}
                                />
                            )

                        })}
                    </div>

                </div>
            </div>
        );
    }

}

export default ExistingSystems;

