import React from 'react';
import '../../Font.css';
import './Contact.css';
import ObjectContact from './ObjectContact/ObjectContact';

class Contact extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {

        return (
            <div className="ContactWrapper">

                <div className="PageTitle">How to contact the authors</div>
                <div className="TitleSeparator"></div>

                <div className="contactList">

                <ObjectContact authorName="Laura Pruszko" authorField="HCI" authorEmail="laura.pruszko[at]univ-grenoble-alpes.fr" authorWebsite="http://iihm.imag.fr/en/member/pruszko/"/>

                <ObjectContact authorName="Céline Coutrix" authorField="HCI" authorEmail="celine.coutrix[at]imag.fr" authorWebsite="http://iihm.imag.fr/coutrix/"/>

                <ObjectContact authorName="Yann Laurillau" authorField="HCI" authorEmail="yann.laurillau[at]univ-grenoble-alpes.fr" authorWebsite="http://iihm.imag.fr/laurillau/"/>

                <ObjectContact authorName="Julien Bourgeois" authorField="robotics" authorEmail="julien.bourgeois[at]univ-fcomte.fr" authorWebsite="https://members.femto-st.fr/julien-bourgeois/"/>

                <ObjectContact authorName="Benoit Piranda" authorField="robotics" authorEmail="benoit.piranda[at]univ-fcomte.fr" authorWebsite="https://benoitpiranda.fr/"/>

                </div>

            </div>
        );
    }

}

export default Contact;