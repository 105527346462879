import React from 'react';

class SystemsModel {

    /**
     * Constructor building a model for the system with each parameter.
     * @param id
     * @param name
     * @param domain
     * @param architecture
     * @param IO
     * @param processing
     * @param powerStorage
     * @param size
     * @param shape
     * @param speed
     * @param density
     * @param structure
     * @param strength
     * @param hierarchy
     * @param smoothness
     * @param resolution
     * @param combination
     * @param shapechange_Amplitude
     * @param shapechange_ZeroCrossing
     * @param shapechange_Curvature
     * @param shapechange_Speed
     * @param shapechange_Modularity
     * @param shapechange_Porosity
     * @param shapechange_Stretchability
     * @param shapechange_Granularity
     * @param shapechange_Strength
     * @param shapechange_SizeLength
     * @param shapechange_SizeArea
     * @param shapechange_SizeVolume
     * @param reversibility
     * @param dimensionality
     * @param volume
     * @param usageConsumption
     * @param control
     * @param interactivity
     * @param safety_Severity
     * @param safety_Avoidance
     * @param safety_Redundancy
     * @param imgPath
     * @param doi
     * @param abstract
     * @param title
     * @param authors
     * @param proceedings
     * @param year
     */

    constructor(id, name, domain, architecture, reconfiguration,
                // MICRO
                IO, processing, powerStorage, size, shape, speed, density, structure, strength,
                // MACRO
                hierarchy, smoothness, resolution, combination, shapechange_Amplitude, shapechange_Closure, shapechange_ZeroCrossing, shapechange_Curvature, shapechange_Speed,
                shapechange_Modularity, shapechange_Porosity, shapechange_Stretchability, shapechange_Granularity, shapechange_Strength, shapechange_SizeLength,
                shapechange_SizeArea, shapechange_SizeVolume, reversibility, dimensionality, volume, usageConsumption, control, interactivity_input, interactivity_output, safety_Severity,
                safety_Avoidance, safety_Redundancy,
                // PAPER INFOS
                imgPath, doi, abstract, title, authors, proceedings, year) {

       // GENERAL PROPERTIES
        this.id = id;
        this.name = name;
        this.domain = domain;
        this.architecture = architecture;
        this.reconfiguration = reconfiguration;

        // MICRO PROPERTIES
        this.IO = IO;
        this.processing = processing;
        this.powerStorage = powerStorage;
        this.size = size;
        this.shape = shape;
        this.speed = speed;
        this.density = density;
        this.structure = structure;
        this.strength = strength;

        // MACRO PROPERTIES
        this.hierarchy = hierarchy;
        this.smoothness = smoothness;
        this.resolution = resolution;
        this.combination = combination;
        this.shapechange_Amplitude = shapechange_Amplitude;
        this.shapechange_Closure = shapechange_Closure;
        this.shapechange_ZeroCrossing = shapechange_ZeroCrossing;
        this.shapechange_Curvature = shapechange_Curvature;
        this.shapechange_Speed = shapechange_Speed;
        this.shapechange_Modularity = shapechange_Modularity;
        this.shapechange_Porosity = shapechange_Porosity;
        this.shapechange_Stretchability = shapechange_Stretchability;
        this.shapechange_Granularity = shapechange_Granularity;
        this.shapechange_Strength = shapechange_Strength;
        this.shapechange_SizeLength = shapechange_SizeLength;
        this.shapechange_SizeArea = shapechange_SizeArea;
        this.shapechange_SizeVolume = shapechange_SizeVolume;
        this.reversibility = reversibility;
        this.dimensionality = dimensionality;
        this.volume = volume;
        this.usageConsumption = usageConsumption;
        this.control = control;
        this.interactivity_input = interactivity_input;
        this.interactivity_output = interactivity_output;
        this.safety_Severity = safety_Severity;
        this.safety_Avoidance = safety_Avoidance;
        this.safety_Redundancy = safety_Redundancy;

        // PAPER INFOS
        this.imgPath = imgPath;
        this.doi = doi;
        this.abstract = abstract;
        this.title = title;
        this.authors = authors;
        this.proceedings = proceedings;
        this.year = year;

    }
}

export default SystemsModel;