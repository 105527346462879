import React from 'react';
import './FilterMacro.css';
import '../../../../Font.css';
import '../../../../icones.css';
import Switch from "../../../Utils/Switch/Switch";
import Checkbox from "../../../Utils/Checkbox/Checkbox";

class FilterMacro extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isDetachable: false,
            isMultistable: false,
            isSafe: false,
            isSpaceConstrained: false
        };

        this.toggleMultistable = this.toggleMultistable.bind(this);
        this.toggleSafe = this.toggleSafe.bind(this);
        this.toggleSpaceConstrained = this.toggleSpaceConstrained.bind(this);
    }


    toggleMultistable() {
        let temp_multistable = this.state.isMultistable;
        this.setState({isMultistable: !temp_multistable});
    }

    toggleSafe() {
        let temp_safe = this.state.isSafe;
        this.setState({isSafe: !temp_safe});
    }

    toggleSpaceConstrained() {
        let temp_spaceConstrained = this.state.isSpaceConstrained;
        this.setState({isSpaceConstrained: !temp_spaceConstrained});
    }

    render() {
        return (
            <div className="FilterMacroWrapper">

                    <div className="FormFilter">

                        <div className={"FilterFormElement DimensionalityForm " + (this.props.dimensionality != undefined && this.props.dimensionality.length != 0 ? "highlighted" : "") }>
                            <div className="formObjectTitle " >Dimensionality</div>
                            <div className="FormAnswersWrapper">
                                <Checkbox value="2D"
                                          checked={this.props.dimensionality.find(element => element == '2')}
                                          onChange={this.props.updateDimensionality} />

                                <Checkbox value="2.5D"
                                          checked={this.props.dimensionality.find(element => element == '2.5')}
                                          onChange={this.props.updateDimensionality} />

                                <Checkbox value="3D"
                                          checked={this.props.dimensionality.find(element => element == '3')}
                                          onChange={this.props.updateDimensionality} />
                        </div>
                        </div>

                        <div className="modaleSeparator"/>

                        <div className={"FilterFormElement SCForm "+ (this.props.shapeChange != undefined && this.props.shapeChange.length != 0 ? "highlighted" : "")}>
                            <div className="formObjectTitle">Shape-changing ability</div>
                            <div className="FormAnswersWrapper">
                                <div className="FormAnswersWrapper TwoLevels">
                                    <div className="FirstLevel">
                                    <Checkbox value="Amplitude"
                                              checked={this.props.shapeChange.find(element => element == '1')}
                                              onChange={this.props.updateShapeChange} />

                                    <Checkbox value="Closure"
                                              checked={this.props.shapeChange.find(element => element == '2')}
                                              onChange={this.props.updateShapeChange} />

                                    <Checkbox value="Curvature"
                                              checked={this.props.shapeChange.find(element => element == '3')}
                                              onChange={this.props.updateShapeChange} />

                                    <Checkbox value="Granularity"
                                              checked={this.props.shapeChange.find(element => element == '4')}
                                              onChange={this.props.updateShapeChange} />

                                    <Checkbox value="Modularity"
                                              checked={this.props.shapeChange.find(element => element == '5')}
                                              onChange={this.props.updateShapeChange} />

                                    <Checkbox value="Porosity"
                                              checked={this.props.shapeChange.find(element => element == '6')}
                                              onChange={this.props.updateShapeChange} />
                                    </div>

                                    <div className="SecondLevel">

                                    <Checkbox value="Size"
                                              checked={this.props.shapeChange.find(element => element == '7')}
                                              onChange={this.props.updateShapeChange} />

                                    <Checkbox value="Speed"
                                              checked={this.props.shapeChange.find(element => element == '8')}
                                              onChange={this.props.updateShapeChange} />

                                    <Checkbox value="Strength"
                                              checked={this.props.shapeChange.find(element => element == '9')}
                                              onChange={this.props.updateShapeChange} />
                                    <Checkbox value="Stretchability"
                                              checked={this.props.shapeChange.find(element => element == '10')}
                                              onChange={this.props.updateShapeChange} />

                                    <Checkbox value="Zero-crossing"
                                              checked={this.props.shapeChange.find(element => element == '11')}
                                              onChange={this.props.updateShapeChange} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modaleSeparator"/>

                        <div className={"FilterFormElement "+ (this.props.isReversible != undefined && this.props.isReversible.length != 0 ? "highlighted" : "") }>
                            <div className="formObjectTitle">Reversibility</div>
                            <div className="FormAnswersWrapper">
                                <Checkbox value="Yes"
                                          checked={this.props.isReversible.find(element => element == '1')}
                                          onChange={this.props.updateReversible} />

                                <Checkbox value="No"
                                          checked={this.props.isReversible.find(element => element == '2')}
                                          onChange={this.props.updateReversible} />
                            </div>
                        </div>

                        <div className="modaleSeparator"/>

                        <div className={"FilterFormElement " + (this.props.actuation != undefined && this.props.actuation.length != 0 ? "highlighted" : "") }>
                            <div className="formObjectTitle">Control over shape-change</div>
                            <div className="FormAnswersWrapper">
                                <Checkbox value="Directly controlled"
                                          checked={this.props.actuation.find(element => element == '1')}
                                          onChange={this.props.updateActuation} />

                                <Checkbox value="Negotiated"
                                          checked={this.props.actuation.find(element => element == '2')}
                                          onChange={this.props.updateActuation} />

                                <Checkbox value="Indirectly controlled"
                                          checked={this.props.actuation.find(element => element == '3')}
                                          onChange={this.props.updateActuation} />

                                <Checkbox value="System controlled"
                                          checked={this.props.actuation.find(element => element == '4')}
                                          onChange={this.props.updateActuation} />
                            </div>
                        </div>

                        <div className="modaleSeparator"/>

                        <div className={"FilterFormElement Centered "+  (this.props.isCombinationPhysicalVirtual != undefined && this.props.isCombinationPhysicalVirtual.length != 0 ? "highlighted" : "")}>
                            <div className="formObjectTitle">Combination between physical and digital representation(s)</div>
                            <div className="FormAnswersWrapper">
                                <Checkbox value="Yes"
                                          checked={this.props.isCombinationPhysicalVirtual.find(element => element == '1')}
                                          onChange={this.props.updateCombinationPhysicalVirtual} />

                                <Checkbox value="No"
                                          checked={this.props.isCombinationPhysicalVirtual.find(element => element == '2')}
                                          onChange={this.props.updateCombinationPhysicalVirtual} />
                            </div>
                        </div>

                        <div className="modaleSeparator"/>

                        <div className={"FilterFormElement " + (this.props.surfaceResolution != undefined && this.props.surfaceResolution.length != 0 ? "highlighted" : "") }>
                            <div className="formObjectTitle">Surface and resolution</div>
                            <div className="FormAnswersWrapper">
                                <Checkbox value="Individual pixel/voxel/sensel"
                                          checked={this.props.surfaceResolution.find(element => element == '1')}
                                          onChange={this.props.updateSurfaceResolution} />

                                <Checkbox value="Part of a screen"
                                          checked={this.props.surfaceResolution.find(element => element == '2')}
                                          onChange={this.props.updateSurfaceResolution} />
                            </div>
                        </div>

                        <div className="modaleSeparator"/>

                        <div className={"FilterFormElement " + (this.props.hierarchy != undefined && this.props.hierarchy.length != 0 ? "highlighted" : "") }>
                            <div className="formObjectTitle">Hierarchy</div>
                            <div className="FormAnswersWrapper">
                                <Checkbox value="Standalones"
                                          checked={this.props.hierarchy.find(element => element == '1')}
                                          onChange={this.props.updateHierarchy} />

                                <Checkbox value="Standalones + Satellites"
                                          checked={this.props.hierarchy.find(element => element == '2')}
                                          onChange={this.props.updateHierarchy} />
                            </div>
                        </div>

                        <div className="modaleSeparator"/>

                        <div className={"FilterFormElement SCForm "+  (this.props.isInteractive != undefined && this.props.isInteractive.length != 0 ? "highlighted" : "") }>
                            <div className="formObjectTitle">Interactivity</div>
                            <div className="FormAnswersWrapper TwoLevels">
                                <div className="FirstLevel">
                                <Checkbox value="No output"
                                          checked={this.props.isInteractive.find(element => element == '1')}
                                          onChange={this.props.updateInteractivity} />

                                <Checkbox value="Output through shape-change"
                                          checked={this.props.isInteractive.find(element => element == '2')}
                                          onChange={this.props.updateInteractivity} />

                                <Checkbox value="Output through other modalities"
                                          checked={this.props.isInteractive.find(element => element == '3')}
                                          onChange={this.props.updateInteractivity} />

                                </div>
                                <div className="SecondLevel">
                                <Checkbox value="No input"
                                          checked={this.props.isInteractive.find(element => element == '4')}
                                          onChange={this.props.updateInteractivity} />

                                <Checkbox value="Input through shape-change"
                                          checked={this.props.isInteractive.find(element => element == '5')}
                                          onChange={this.props.updateInteractivity} />

                                <Checkbox value="Input through other modalities"
                                          checked={this.props.isInteractive.find(element => element == '6')}
                                          onChange={this.props.updateInteractivity} />
                                </div>
                            </div>
                        </div>

                        <div className="modaleSeparator"/>

                        <div className={"FilterFormElement Centered "+ (this.state.isSafe ? "highlighted" : "") }>
                            <div className="formObjectTitle">Safety</div>
                            <Switch checked={this.state.isSafe} onChange={this.toggleSafe} />
                        </div>

                        <div className="modaleSeparator"/>

                        <div className={"FilterFormElement Centered "+ (this.state.isSpaceConstrained ? "highlighted" : "") }>
                            <div className="formObjectTitle">Space constrained</div>
                            <Switch checked={this.state.isSpaceConstrained} onChange={this.toggleSpaceConstrained} />
                        </div>

                        <div className="modaleSeparator"/>

                        <div className={"FilterFormElement Centered "+ (this.state.isMultistable ? "highlighted" : "") }>
                            <div className="formObjectTitle">Usage consumption</div>
                            <Switch checked={this.state.isMultistable} onChange={this.toggleMultistable} />
                        </div>


                    </div>

                </div>
        );
    }

}

export default FilterMacro;
