import React from 'react';
import './Font.css';
import './App.css';
import AppNavBar from './Components/AppNavBar/AppNavBar';
import PageRequirements from './Components/Requirements/PageRequirements/PageRequirements';
import ExistingSystems from "./Components/ExistingSystems/ExistingSystems";
import Contact from "./Components/Contact/Contact";
import FilterModel from "./Models/FilterModel";
import SystemsListModel from "./Models/SystemsListModel";

class App extends React.Component {

    constructor(props) {
        super(props);

        this.navigateTo = this.navigateTo.bind(this);

        this.state = {
            activePage: '1',
            systems: new SystemsListModel(),
            filters: new FilterModel(),
            filterList: new Map()
        };

        this.deleteFilter = this.deleteFilter.bind(this);
        this.filterSystems = this.filterSystems.bind(this);
        this.updateSystems = this.updateSystems.bind(this);
    }

     componentDidMount() {
        this.updateSystems();
    }

    async updateSystems() {
        try {
            let temp_systems = this.state.systems;
            await temp_systems.getAllSystems();

            this.setState({systems: temp_systems});

        } catch (error) {
            console.log(error);
        }

    }

    /*
    * Updates the state of filters and the filteredSystems, according to new the new list of filters provided.
    * newFilters: updated list of filters, used here and in FilterArchiReconf.
    */
    filterSystems (newFilters) {

        /* Update the list to filter it */
        let temp_systems = this.state.systems;
        temp_systems.filterSystems(newFilters);

        /* Update de model in the state */
        this.setState({filters: newFilters, systems: temp_systems});
    }

    navigateTo(newActivePage) {
        this.setState({activePage: newActivePage});
    }

    deleteFilter(id) {
        this.state.filters.delete(id);
        this.filterSystems(this.state.filters);
    }

    render() {

        let pageToDisplay;

        switch(this.state.activePage) {
            case '1': pageToDisplay = <PageRequirements
                navigateTo={this.navigateTo} />;
                break;
            case '2': pageToDisplay = <ExistingSystems
                filters={this.state.filters}
                systems={this.state.systems}
                filterSystems={this.filterSystems}
                filterList={this.state.filterList}
                deleteFilter={this.deleteFilter}
                navigateTo={this.navigateTo}
            />;
                break;
           /* case '3': pageToDisplay = <DesignParameters />;
                break;*/
            case '4': pageToDisplay = <Contact />;
                break;
            default: pageToDisplay = <div>Unknown Page</div>;
        }

        return (
            <div className="App">
                <AppNavBar activePage={this.state.activePage} navigateTo={this.navigateTo}/>

                <div className="AppContent">
                    {pageToDisplay}
                </div>
            </div>
        );
    }


}

export default App;
