import React from 'react';
import '../../../Font.css';
import './ObjectContact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faCode } from '@fortawesome/free-solid-svg-icons'

class ObjectContact extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }


    render() {

        return (
            <div className="ContactWrapper">

                <div className="contactCard">
                    <div className={"authorName " + this.props.authorField}>{this.props.authorName}</div>

                    <div className="contactDetails">
                        <div className="contactEmail">
                            <FontAwesomeIcon className={"contactIcon " + this.props.authorField} icon={faEnvelope} />
                            <a className="contactDetail">{this.props.authorEmail}</a>
                        </div>
                        <div className="contactWebsite">
                            <FontAwesomeIcon className={"contactIcon " + this.props.authorField} icon={faCode} />
                            <a className="contactDetail" href={this.props.authorWebsite}>{this.props.authorWebsite}</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default ObjectContact;