import React from 'react';
import './Checkbox.css';

class Checkbox extends React.Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange () {
        this.props.onChange();
    }

    render() {
        return (
            <label className='CheckboxWrapper'>
                <input
                    type="checkbox"
                    name={"checkbox_" + this.props.value}
                    value={this.props.value}
                    className="Checkbox"
                    checked={this.props.checked}
                    onChange={this.props.onChange.bind(this.props.value)}
                />
                <span className="Checkmark"></span>
                <span className="CheckboxLabel">{this.props.value}</span>
            </label>
        );
    }
}

export default Checkbox;