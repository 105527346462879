import React from 'react';
import './Filters.css';
import '../../../Font.css';
import '../../../icones.css';
import FilterModel from "../../../Models/FilterModel";
import ObjectExistingSystem from "../ObjectExistingSystem/ObjectExistingSystem";
import FilterObject from "./FilterObject/FilterObject";
import FilterModale from "./FilterModale/FilterModale";

class Filters extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isModaleActive: false
        };

        this.toggleModaleActive = this.toggleModaleActive.bind(this);

    }

    toggleModaleActive () {
        let temp_activeModale = this.state.isModaleActive;
        this.setState({isModaleActive: !temp_activeModale});
    }


    render() {

        var filtersToDisplay = [];
        var filterModale = "";

        this.props.filters.activeFilters.forEach(filter => filtersToDisplay.push(
                <FilterObject
                    name={filter}
                    deleteFilter={this.props.deleteFilter}
                    toggleModaleActive={this.toggleModaleActive}
                />
            ));

        if (this.state.isModaleActive) {
            filterModale = <FilterModale
                toggleModaleActive={this.toggleModaleActive}
                filterSystems={this.props.filterSystems}
                filters={this.props.filters}
                navigateTo={this.props.navigateTo}
            />
        }

        return (
            <div className="FilterWrapper">
                {filterModale}
                <div className="FiltersList">

                    {filtersToDisplay}

                    <div className="AddFilterBtn" onClick={this.toggleModaleActive}>
                        <div className="btnPlus"/>
                        <div className="btnPlusHover"/>
                        <div className="textAddFilter">Add Filter</div>
                    </div>
                </div>
                <div className="FilterSeparator"/>
            </div>
        );
    }

}

export default Filters;
