import React from 'react';
import './Compare.css';
import '../Describe/Describe.css';
import '../../../Font.css';
import '../../../icones.css';

class Compare extends React.Component {

    constructor(props) {
        super(props);

    }


    render() {

        return (
            <div className="DescribeWrapper">

                <div className="FilterModaleWrapper">
                    <div className="backgroundModale" onClick={this.props.toggleCompare} />

                    <div className="ContentModaleWrapper">

                        <div className="modaleDescriptionHeader">
                            <div className="closeBtnModaleWrapper">
                                <div className="btnCrossHover" onClick={this.props.toggleCompare}/>
                                <div className="btnCross"/>
                            </div>
                            <div className="modaleTitle">Comparison</div>
                        </div>


                        <div className="FormDescribeCompareFilter">


                            <div className="SectionTitle">Architecture and Reconfiguration</div>

                            <div className="SystemComparedNames">
                                <div className="SystemCompareName">LineFORM</div>
                                <div className="SystemCompareName">ChainFORM</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Architecture</div>
                                <div className="formTitleSeparatorComparison"/>
                                <div className="DescribeCompareObjectValue">Chain</div>
                                <div className="formTitleSeparatorComparison"/>
                                <div className="DescribeCompareObjectValue">Chain</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Reconfiguration</div>

                                <div className="DescribeCompareObjectValue">Self-reconfigurable</div>

                                <div className="DescribeCompareObjectValue">Self-reconfigurable</div>
                            </div>

                            <div className="SectionTitle">Properties at the scale of the modules (micro)</div>

                            <div className="SystemComparedNames">
                                <div className="SystemCompareName">LineFORM</div>
                                <div className="SystemCompareName">ChainFORM</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle different">Shape</div>

                                <div className="DescribeCompareObjectValue different">Rectangle parallelepiped w/ bracket</div>

                                <div className="DescribeCompareObjectValue different">2 joined rectangle parallelepiped</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle different">Size</div>

                                <div className="DescribeCompareObjectValue different">32x70x40mm</div>

                                <div className="DescribeCompareObjectValue different">25×30×12mm</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle different">Density</div>

                                <div className="DescribeCompareObjectValue different">Lower (bigger modules)</div>

                                <div className="DescribeCompareObjectValue different">Higher (smaller modules)</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Structure</div>

                                <div className="DescribeCompareObjectValue">Chained</div>

                                <div className="DescribeCompareObjectValue">Chained</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle different">Strength</div>

                                <div className="DescribeCompareObjectValue different">8.3kg.cm</div>

                                <div className="DescribeCompareObjectValue different">8.kg.cm</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Speed</div>

                                <div className="DescribeCompareObjectValue">0.103 sec/60 deg</div>

                                <div className="DescribeCompareObjectValue">0.10 sec/60 deg</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle different">Input/Output</div>

                                <div className="DescribeCompareObjectValue different">None</div>

                                <div className="DescribeCompareObjectValue different">Touch sensors, LEDs</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle different">Processing</div>

                                <div className="DescribeCompareObjectValue different">Limited -- Externalized Arduino Mega</div>
                                <div className="DescribeCompareObjectValue different">Limited -- Externalized Teensy 3.2</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Power storage</div>

                                <div className="DescribeCompareObjectValue">Mains-operated</div>
                                <div className="DescribeCompareObjectValue">Mains-operated</div>
                            </div>

                            <div className="SectionTitle">Properties at the scale of the interface (macro)</div>

                            <div className="SystemComparedNames">
                                <div className="SystemCompareName">LineFORM</div>
                                <div className="SystemCompareName">ChainFORM</div>
                            </div>

                            <div className={"DescribeCompareFormElement ShapeChange" }>
                                <div className="ShapeChangeFeaturesWrapper">
                                    <div className="DescribeCompareObjectTitle">Shape-change ability</div>
                                    <div className="DescribeCompareObjectTitle Feature different">Amplitude</div>
                                    <div className="DescribeCompareObjectTitle Feature">Closure</div>
                                    <div className="DescribeCompareObjectTitle Feature different">Curvature</div>
                                    <div className="DescribeCompareObjectTitle Feature">Granularity</div>
                                    <div className="DescribeCompareObjectTitle Feature">Modularity</div>
                                    <div className="DescribeCompareObjectTitle Feature">Porosity</div>
                                    <div className="DescribeCompareObjectTitle Feature">Size: Length</div>
                                    <div className="DescribeCompareObjectTitle Feature">Size: Area</div>
                                    <div className="DescribeCompareObjectTitle Feature different">Size: Volume</div>
                                    <div className="DescribeCompareObjectTitle Feature">Speed</div>
                                    <div className="DescribeCompareObjectTitle Feature">Strength</div>
                                    <div className="DescribeCompareObjectTitle Feature">Stretchability</div>
                                    <div className="DescribeCompareObjectTitle Feature different">Zero-crossing</div>
                                </div>

                                <div className="ShapeChangeFeaturesWrapper">
                                    <div className="void20px" />
                                    <div className="DescribeCompareObjectValue Feature different">Lower (17 modules)</div>
                                    <div className="DescribeCompareObjectValue Feature">not mentioned</div>
                                    <div className="DescribeCompareObjectValue Feature different">Higher (0–232° between two modules)</div>
                                    <div className="DescribeCompareObjectValue Feature">max. 0.04 cp/cm2</div>
                                    <div className="DescribeCompareObjectValue Feature">No</div>
                                    <div className="DescribeCompareObjectValue Feature">No</div>
                                    <div className="DescribeCompareObjectValue Feature">Yes (max. 186cm)</div>
                                    <div className="DescribeCompareObjectValue Feature">Yes (max. not mentioned)</div>
                                    <div className="DescribeCompareObjectValue Feature different">Yes (max. not mentioned)</div>
                                    <div className="DescribeCompareObjectValue Feature">0.103 sec/60°</div>
                                    <div className="DescribeCompareObjectValue Feature">not mentioned</div>
                                    <div className="DescribeCompareObjectValue Feature">No</div>
                                    <div className="DescribeCompareObjectValue Feature different">0–8 (17 modules)</div>
                                </div>
                                <div className="ShapeChangeFeaturesWrapper">
                                    <div className="void20px" />
                                    <div className="DescribeCompareObjectValue Feature different">Higher (max. 32 modules)</div>
                                    <div className="DescribeCompareObjectValue Feature">not mentioned</div>
                                    <div className="DescribeCompareObjectValue Feature different">Lower (0–119.5° between two modules)</div>
                                    <div className="DescribeCompareObjectValue Feature">max. 0.13 cp/cm2</div>
                                    <div className="DescribeCompareObjectValue Feature">No</div>
                                    <div className="DescribeCompareObjectValue Feature">No</div>
                                    <div className="DescribeCompareObjectValue Feature">Yes (max. not mentioned)</div>
                                    <div className="DescribeCompareObjectValue Feature">Yes (max. not mentioned)</div>
                                    <div className="DescribeCompareObjectValue Feature different">No</div>
                                    <div className="DescribeCompareObjectValue Feature">0.10 sec/60°</div>
                                    <div className="DescribeCompareObjectValue Feature">not mentioned</div>
                                    <div className="DescribeCompareObjectValue Feature">No</div>
                                    <div className="DescribeCompareObjectValue Feature different">0–16 (max. 32 modules)</div>
                                </div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Reversibility</div>

                                <div className="DescribeCompareObjectValue">Yes</div>
                                <div className="DescribeCompareObjectValue">Yes</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle different">Dimensionality</div>

                                <div className="DescribeCompareObjectValue different">3D</div>
                                <div className="DescribeCompareObjectValue different">2D</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle different">Interactivity</div>

                                <div className="DescribeCompareObjectValue different">Shape-change (input/output)</div>
                                <div className="DescribeCompareObjectValue different">Shape-change + other modalities (intput/output) </div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Control over shape-change</div>

                                <div className="DescribeCompareObjectValue">Negotiated</div>
                                <div className="DescribeCompareObjectValue">Negotiated</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Volume required for shape-change</div>

                                <div className="DescribeCompareObjectValue">Not mentioned</div>
                                <div className="DescribeCompareObjectValue">Not mentioned</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Safety</div>

                                <div className="DescribeCompareObjectValue">Not mentioned</div>
                                <div className="DescribeCompareObjectValue">Not mentioned</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Combination b/w representations</div>

                                <div className="DescribeCompareObjectValue">Yes</div>
                                <div className="DescribeCompareObjectValue">Yes</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Hierarchy</div>

                                <div className="DescribeCompareObjectValue">Satellites + externalized standalone (microcontroller)</div>
                                <div className="DescribeCompareObjectValue">Satellites + externalized standalone (microcontroller)</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle different">Resolution</div>

                                <div className="DescribeCompareObjectValue different">Lower (bigger modules)</div>
                                <div className="DescribeCompareObjectValue different">Higher (smaller modules + 8 LEDs)</div>

                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle different">Smoothness</div>

                                <div className="DescribeCompareObjectValue different">Seamless -- Fabric cover over modules</div>
                                <div className="DescribeCompareObjectValue different">Not seamless -- Gaps between modules</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Usage consumption</div>

                                <div className="DescribeCompareObjectValue">not mentioned</div>
                                <div className="DescribeCompareObjectValue">83mA.h per module</div>
                            </div>

                        </div>


                    </div>
                </div>

            </div>

        );
    }

}

export default Compare;
