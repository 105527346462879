import React from 'react';
import '../../Font.css';
import './AppNavBar.css';

class AppNavBar extends React.Component {

    constructor(props) {
        super(props);

        this.handleNavigateTo = this.handleNavigateTo.bind(this);

        this.state = {
            scrolled: false
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        let actualScrolled = (window.pageYOffset > 100 ? true : false);
        if (this.state.scrolled !== actualScrolled) {
            this.setState({scrolled: actualScrolled});
        }
    }

    handleNavigateTo(event) {
        let value = event.target.getAttribute('value');
        this.props.navigateTo(value);
    }

    render() {

        let UpperNavBar;
        if (!this.state.scrolled) {
            UpperNavBar = <div className="UpperNavbar">
                <p className="UpperNavbarTitle">Molecular HCI</p>
            </div>;
        } else {
            UpperNavBar = <div className="UpperNavbar hidden"/>;
        }

        //<div className={"Thumbnail" + (this.props.activePage === '3' ? " active" : "")} value={3} onClick={this.handleNavigateTo}>Design Parameters</div>
        //                         <div className="ThumbnailSeparator"/>

        return (
            <div className="NavbarWrapper">
                {UpperNavBar}
                <div className="LowerNavbar">
                    <div  className="NavbarSeparator" />
                    <div className="ThumbnailWrapper">
                        <div className={"Thumbnail" + (this.props.activePage === '1' ? " active" : "")} value={1} onClick={this.handleNavigateTo}>Properties</div>
                        <div className="ThumbnailSeparator"/>
                        <div className={"Thumbnail" + (this.props.activePage === '2' ? " active" : "")} value={2} onClick={this.handleNavigateTo}>Existing Systems</div>
                        <div className="ThumbnailSeparator"/>
                        <div className={"Thumbnail" + (this.props.activePage === '4' ? " active" : "")} value={4} onClick={this.handleNavigateTo}>Contact</div>
                    </div>
                </div>
            </div>
        );
    }

}

export default AppNavBar;