import React from 'react';
import './FilterModale.css';
import '../../../../Font.css';
import '../../../../icones.css';
import FilterModel from "../../../../Models/FilterModel";
import FilterMacro from "../FilterMacro/FilterMacro";
import FilterMicro from "../FilterMicro/FilterMicro";
import FilterArchiReconf from "../FilterArchiReconf/FilterArchiReconf";

class FilterModale extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filterPageToDisplay: 1,

            architecture: [],
            reconfiguration: [],

            dimensionality: [],
            shapeChange:[],
            isReversible: [],
            actuation: [],
            isCombinationPhysicalVirtual: [],
            isDetachable: false,
            surfaceResolution: [],
            hierarchy: [],
            isMultistable: false,
            isInteractive: [],
            isSafe: false,
            isSpaceConstrained: false,


            shape:[]
        };

        this.updateFilters = this.updateFilters.bind(this);

        /* Methods for architecture and reconfiguration */
        this.updateArchitecture = this.updateArchitecture.bind(this);
        this.updateReconfiguration = this.updateReconfiguration.bind(this);

        /* Methods for macro-properties */
        this.updateShapeChange = this.updateShapeChange.bind(this);
        this.updateReversible = this.updateReversible.bind(this);
        this.updateDimensionality = this.updateDimensionality.bind(this);
        this.updateActuation = this.updateActuation.bind(this);
        this.updateHierarchy = this.updateHierarchy.bind(this);
        this.toggleMultistable = this.toggleMultistable.bind(this);
        this.updateInteractivity = this.updateInteractivity.bind(this);
        this.toggleSafe = this.toggleSafe.bind(this);
        this.toggleSpaceConstrained = this.toggleSpaceConstrained.bind(this);
        this.updateCombinationPhysicalVirtual = this.updateCombinationPhysicalVirtual.bind(this);
        this.toggleDetachable = this.toggleDetachable.bind(this);
        this.updateSurfaceResolution = this.updateSurfaceResolution.bind(this);

        /* Methods for micro-properties */
        this.updateShape = this.updateShape.bind(this);

        /* Methods for switching filters pages */
        this.showArchiRecongFilters = this.showArchiRecongFilters.bind(this);
        this.showMacroFilters = this.showMacroFilters.bind(this);
        this.showMicroFilters = this.showMicroFilters.bind(this);

    }

    componentDidMount() {
        this.setState({
            architecture: this.props.filters.architecture,
            dimensionality: this.props.filters.dimensionality,
            isReversible: this.props.filters.isReversible,
            actuation: this.props.filters.actuation,
            isCombinationPhysicalVirtual:this.props.filters.isCombinationPhysicalVirtual,
            isDetachable:this.props.filters.isDetachable,
            surfaceResolution:this.props.filters.surfaceResolution,
            hierarchy:this.props.filters.hierarchy,
            isMultistable: this.props.filters.isMultistable,
            isInteractive:this.props.filters.isInteractive,
            isSafe:this.props.filters.isSafe,
            isSpaceConstrained:this.props.filters.isSpaceConstrained
        });
    }

    /* ====== UPDATE ARCHITECTURE AND RECONFIGURATION ====== */

    updateArchitecture (e) {
        let temp_architecture = this.state.architecture;
        let value = e.target.value;

        switch (value) {
            case "Lattice" : value = "lattice";
                break;
            case "Chain" : value = "chain";
                break;
            case "Swarm" : value = "swarm";
                break;
            case "Mobile" : value = "mobile";
                break;
            case "Hybrid" : value = "hybrid";
                break;
            default : value = "";
                break;
        }

        if (temp_architecture.find(element => element == value)) {
            let index = temp_architecture.indexOf(value);
            temp_architecture.splice(index, 1);
        } else {
            temp_architecture.push(value);
        }
        this.setState({architecture: temp_architecture});
    }

    updateReconfiguration(e) {
        let temp_reconfiguration = this.state.reconfiguration;
        let value = e.target.value;

        switch (value) {
            case "Lattice" : value = 1;
                break;
            case "Chain" : value = 2;
                break;
            case "Swarm" : value = 3;
                break;
            case "Mobile" : value = 4;
                break;
            case "Hybrid" : value = 5;
                break;
            default : value = "";
                break;
        }

        if (temp_reconfiguration.find(element => element == value)) {
            let index = temp_reconfiguration.indexOf(value);
            temp_reconfiguration.splice(index, 1);
        } else {
            temp_reconfiguration.push(value);
        }
        this.setState({reconfiguration: temp_reconfiguration});
    }

    /* ====== UPDATE MACRO PROPERTIES ====== */

    updateDimensionality(e) {
        let temp_dimensionality = this.state.dimensionality;
        let value = e.target.value;

        console.log(value);

        switch (value) {
            case "3D" : value = 3;
                break;
            case "2.5D" : value = 2.5;
                break;
            case "2D" : value = 2;
                break;
            default : value = "";
                break;
        }

        if (temp_dimensionality.find(element => element == value)) {
            let index = temp_dimensionality.indexOf(value);
            temp_dimensionality.splice(index, 1);
        } else {
            temp_dimensionality.push(value);
        }
        this.setState({dimensionality: temp_dimensionality});

    }

    updateShapeChange(e) {
        let temp_shapeChange = this.state.shapeChange;
        let value = e.target.value;

        switch (value) {
            case "Amplitude" : value = 1;
                break;
            case "Closure" : value = 2;
                break;
            case "Curvature" : value = 3;
                break;
            case "Granularity" : value = 4;
                break;
            case "Modularity" : value = 5;
                break;
            case "Porosity" : value = 6;
                break;
            case "Size" : value = 7;
                break;
            case "Speed" : value = 8;
                break;
            case "Strength" : value = 9;
                break;
            case "Stretchability" : value = 10;
                break;
            case "Zero-crossing" : value = 11;
                break;
            default : value = "";
                break;
        }

        if (temp_shapeChange.find(element => element == value)) {
            let index = temp_shapeChange.indexOf(value);
            temp_shapeChange.splice(index, 1);
        } else {
            temp_shapeChange.push(value);
        }

        this.setState({shapeChange: temp_shapeChange});
    }

    updateActuation(e) {
        let temp_actuation = this.state.actuation;
        let value = e.target.value;

        switch (value) {
            case "Directly controlled" : value = 1;
                break;
            case "Negotiated" : value = 2;
                break;
            case "Indirectly controlled" : value = 3;
                break;
            case "System controlled" : value = 4;
                break;
            default : value = "";
                break;
        }

        if (temp_actuation.find(element => element == value)) {
            let index = temp_actuation.indexOf(value);
            temp_actuation.splice(index, 1);
        } else {
            temp_actuation.push(value);
        }

        this.setState({actuation: temp_actuation});
    }

    updateCombinationPhysicalVirtual(e) {
        let temp_isCombinationPhysicalVirtual = this.state.isCombinationPhysicalVirtual;

        let value = e.target.value;

        switch (value) {
            case "Yes" : value = 1;
                break;
            case "No" : value = 2;
                break;
            default : value = "";
                break;
        }

        if (temp_isCombinationPhysicalVirtual.find(element => element == value)) {
            let index = temp_isCombinationPhysicalVirtual.indexOf(value);
            temp_isCombinationPhysicalVirtual.splice(index, 1);
        } else {
            temp_isCombinationPhysicalVirtual.push(value);
        }

        this.setState({isCombinationPhysicalVirtual: temp_isCombinationPhysicalVirtual});
    }

    toggleDetachable() {
        let temp_isDatacheable = this.state.isDetachable;
        this.setState({isDetachable: !temp_isDatacheable});
    }

    updateSurfaceResolution(e) {
        let temp_surfaceResolution = this.state.surfaceResolution;
        let value = e.target.value;

        switch (value) {
            case "Individual pixel/voxel/sensel" : value = 1;
                break;
            case "Part of a screen" : value = 2;
                break;
            default : value = "";
                break;
        }

        if (temp_surfaceResolution.find(element => element == value)) {
            let index = temp_surfaceResolution.indexOf(value);
            temp_surfaceResolution.splice(index, 1);
        } else {
            temp_surfaceResolution.push(value);
        }

        this.setState({surfaceResolution: temp_surfaceResolution});
    }

    updateHierarchy(e) {
        let temp_hierarchy = this.state.hierarchy;
        let value = e.target.value;

        switch (value) {
            case "Standalones" : value = 1;
                break;
            case "Standalones + Satellites" : value = 2;
                break;
            default : value = "";
                break;
        }

        if (temp_hierarchy.find(element => element == value)) {
            let index = temp_hierarchy.indexOf(value);
            temp_hierarchy.splice(index, 1);
        } else {
            temp_hierarchy.push(value);
        }
        this.setState({hierarchy: temp_hierarchy});
    }

    updateReversible(e) {
        let temp_reversible = this.state.isReversible;

        let value = e.target.value;

        switch (value) {
            case "Yes" : value = 1;
                break;
            case "No" : value = 2;
                break;
            default : value = "";
                break;
        }

        if (temp_reversible.find(element => element == value)) {
            let index = temp_reversible.indexOf(value);
            temp_reversible.splice(index, 1);
        } else {
            temp_reversible.push(value);
        }

        this.setState({isReversible: temp_reversible});
    }

    toggleMultistable() {
        let temp_multistable = this.state.isMultistable;
        this.setState({isMultistable: !temp_multistable});
    }

    updateInteractivity(e) {
        let temp_interactive = this.state.isInteractive;

        let value = e.target.value;

        switch (value) {
            case "No output": value = 1;
            break;
            case "Output through shape-change" : value = 2;
                break;
            case "Output through other modalities" : value = 3;
                break;

            case "No input": value = 4;
                break;
            case "Input through shape-change" : value = 5;
                break;
            case "Input through other modalities" : value = 6;
                break;

            default : value = "";
                break;
        }

        if (temp_interactive.find(element => element == value)) {
            let index = temp_interactive.indexOf(value);
            temp_interactive.splice(index, 1);
        } else {
            temp_interactive.push(value);
        }

        this.setState({isInteractive: temp_interactive});
    }

    toggleSafe() {
        let temp_safe = this.state.isSafe;
        this.setState({isSafe: !temp_safe});
    }

    toggleSpaceConstrained() {
        let temp_spaceConstrained = this.state.isSpaceConstrained;
        this.setState({isSpaceConstrained: !temp_spaceConstrained});
    }

    /* ====== UPDATE MICRO PROPERTIES ====== */

    updateShape (e) {
        let temp_shape = this.state.shape;
        let value = e.target.value;

        switch (value) {
            case "Cube" : value = 1;
                break;
            case "Quasi-sphere" : value = 2;
                break;
            case "Cylinder" : value = 3;
                break;
            case "Other" : value = 4;
                break;
            default : value = "";
                break;
        }

        if (temp_shape.find(element => element == value)) {
            let index = temp_shape.indexOf(value);
            temp_shape.splice(index, 1);
        } else {
            temp_shape.push(value);
        }
        this.setState({shape: temp_shape});
    }

    /* ====== SWITCH BETWEEN FILTERS PAGES ====== */

    showArchiRecongFilters() {
        this.setState({filterPageToDisplay: 1});
    }

    showMacroFilters() {
        this.setState({filterPageToDisplay: 2});
    }

    showMicroFilters() {
        this.setState({filterPageToDisplay: 3});
    }

    /* ====== APPLY ALL CHANGES ON THE SYSTEM LIST ====== */

    updateFilters() {
        let newFilters = new FilterModel();
        newFilters.updateFilters(this.state.architecture,
            this.state.dimensionality,
            this.state.shapeChange,
            this.state.isReversible,
            this.state.actuation,
            this.state.isCombinationPhysicalVirtual,
            this.state.surfaceResolution,
            this.state.hierarchy,
            this.state.isMultistable,
            this.state.isInteractive,
            this.state.isSafe,
            this.state.isSpaceConstrained);

        this.props.filterSystems(newFilters);

        this.props.toggleModaleActive();
    }

    render() {
        var toDisplay = "";

        if (this.state.filterPageToDisplay == 1) {

            toDisplay = <FilterArchiReconf
                architecture={this.state.architecture}
                reconfiguration={this.state.reconfiguration}

                updateArchitecture={this.updateArchitecture}

                filterSystems={this.props.filterSystems}
                filters={this.props.filters}
                navigateTo={this.props.navigateTo}
                updateFilters={this.updateFilters}
                updateReconfiguration={this.updateReconfiguration}

                updateShape={this.updateShape} />

        }
        else if (this.state.filterPageToDisplay == 2) {

            toDisplay = <FilterMacro
                dimensionality={this.state.dimensionality}
                isReversible={this.state.isReversible}
                actuation={this.state.actuation}
                isCombinationPhysicalVirtual={this.state.isCombinationPhysicalVirtual}
                surfaceResolution={this.state.surfaceResolution}
                hierarchy={this.state.hierarchy}
                isInteractive={this.state.isInteractive}
                shapeChange = {this.state.shapeChange}

                filterSystems={this.props.filterSystems}
                filters={this.props.filters}
                navigateTo={this.props.navigateTo}
                updateDimensionality={this.updateDimensionality}
                updateReversible={this.updateReversible}
                updateActuation={this.updateActuation}
                updateCombinationPhysicalVirtual={this.updateCombinationPhysicalVirtual}
                updateSurfaceResolution={this.updateSurfaceResolution}
                updateHierarchy={this.updateHierarchy}
                updateInteractivity={this.updateInteractivity}
                updateShapeChange={this.updateShapeChange}

                updateActuation={this.updateActuation}
                toggleCombinationPhysicalVirtual={this.toggleCombinationPhysicalVirtual}
                updateFilters={this.updateFilters}
            />

        }
        else if (this.state.filterPageToDisplay == 3) {

            toDisplay = <FilterMicro
                shape={this.state.shape}
                filterSystems={this.props.filterSystems}
                filters={this.props.filters}
                navigateTo={this.props.navigateTo}
                updateFilters={this.updateFilters}

                updateShape={this.updateShape}

            />

        }

        return (
            <div className="FilterModaleWrapper">
                <div className="backgroundModale" onClick={this.props.toggleModaleActive} />

                <div className="ContentModaleWrapper">

                    <div className="modaleHeader">
                        <div className="closeBtnModaleWrapper">
                            <div className="btnCrossHover" onClick={this.props.toggleModaleActive}/>
                            <div className="btnCross"/>
                        </div>
                        <div className="modaleTitle">Select your filters</div>
                        <div className="modaleDescription">For more information about those filters, please refer to the
                            <a className="link" onClick={() => this.props.navigateTo('1')}> Properties </a>
                            section.</div>

                        <div className="choiceMacroMicro">
                            <div className={"choiceMacro" + (this.state.filterPageToDisplay == 1 ? " active" : "")} onClick={this.showArchiRecongFilters}>Architecture & Reconfiguration</div>
                            <div className={"choiceMacro" + (this.state.filterPageToDisplay == 2 ? " active" : "")} onClick={this.showMacroFilters}>Macro-scale properties</div>
                            <div className={"choiceMicro" + (this.state.filterPageToDisplay == 3 ? " active" : "")} onClick={this.showMicroFilters}>Micro-scale properties</div>
                        </div>
                    </div>

                    <div className="FiltersListWrapper">
                     {toDisplay}
                    </div>


                    <div className="modaleFooter">
                        <div className="optionBtn">
                            <button className="cancelBtn" onClick={this.props.toggleModaleActive}>
                                Cancel
                            </button>
                            <button className="saveBtn" onClick={this.updateFilters}>
                                Save
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        );
    }

}

export default FilterModale;
