import SystemsModel from "./SystemsModel";


class SystemsListModel {

    /**
     * Constructor of SystemListModel.
     * SystemsID: list of all systems ID from the database.
     * allSystems: list of all systems from the database, with their parameters.
     * filteredSystems: list of systems to display on the website, according to the filters entered by the user.
     */
    constructor() {

        this.systemsID = [];
        this.allSystems = [];
        this.filteredSystems = [];


        this.getAllSystems = this.getAllSystems.bind(this);
    }

    /**
     * Fetch all the data from the couchDB database and translate it into a list of SystemModels.
     */
    getAllSystems() {
        // Use the first line if you are in dev mode, second if build mode
	//fetch('http://localhost:3001/all')
        fetch('http://molecularhci.imag.fr/all')
            .then(response => response.json())
            .then (
            function(response) {
                this.systemsID = response.documents;

                if (this.systemsID.length > 0) {

                    this.systemsID.forEach(id => {
// Use the first line if you are in dev mode, second if build mode
//                         fetch(`/byID?name=${encodeURIComponent(id)}`)
                        fetch(`http://molecularhci.imag.fr/all/byID?name=${encodeURIComponent(id)}`)
                            .then(response => response.json())
                            .then (
                                function (response) {
                                    let newSystem = new SystemsModel(
                                        response.details._id,
                                        response.details.name,
                                        response.details.domain,
                                        response.details.architecture,
                                        response.details.reconfiguration,
                                        // MICRO PROPERTIES
                                        response.details.IO,
                                        response.details.processing,
                                        response.details.powerStorage,
                                        response.details.size,
                                        response.details.shape,
                                        response.details.speed,
                                        response.details.density,
                                        response.details.structure,
                                        response.details.strength,

                                        // MACRO PROPERTIES
                                        response.details.hierarchy,
                                        response.details.smoothness,
                                        response.details.resolution,
                                        response.details.combination,
                                        response.details.shapechange_Amplitude,
                                        response.details.shapechange_Closure,
                                        response.details.shapechange_ZeroCrossing,
                                        response.details.shapechange_Curvature,
                                        response.details.shapechange_Speed,
                                        response.details.shapechange_Modularity,
                                        response.details.shapechange_Porosity,
                                        response.details.shapechange_Stretchability,
                                        response.details.shapechange_Granularity,
                                        response.details.shapechange_Strength,
                                        response.details.shapechange_SizeLength,
                                        response.details.shapechange_SizeArea,
                                        response.details.shapechange_SizeVolume,
                                        response.details.reversibility,
                                        response.details.dimensionality,
                                        response.details.volume,
                                        response.details.usageConsumption,
                                        response.details.control,
                                        response.details.interactivity_input,
                                        response.details.interactivity_output,
                                        response.details.safety_Severity,
                                        response.details.safety_Avoidance,
                                        response.details.safety_Redundancy,

                                        // PAPER INFO
                                        response.details.imgPath,
                                        response.details.doi,
                                        response.details.abstract,
                                        response.details.title,
                                        response.details.authors,
                                        response.details.proceedings,
                                        response.details.year
                                    );

                                    this.allSystems.push(newSystem);
                                }.bind(this)
                            ).then (
                                function (test) {
                                    this.allSystems.sort(function(a, b) {
                                        return parseFloat(b.year) - parseFloat(a.year);
                                    });
                                }.bind(this)
                        );
                    });
                    this.filteredSystems = this.allSystems;
                }
            }.bind(this));


    }

    filterSystems (filterModel) {

        let temp_filteredSystems = [];

        this.allSystems.forEach(system => {
            let shouldBeDisplayed = true;

            /* Filter by architercture */
            if (filterModel.architecture.length !== 0
                && !(filterModel.architecture.find(element => element == system.architecture))) {
                shouldBeDisplayed = false;
                console.log("Does not meet dimensionality");
            }

            /* Filter by dimensionality */

            if (filterModel.dimensionality.length !== 0
                && !(filterModel.dimensionality.find(element => element == system.dimensionality))) {
                    shouldBeDisplayed = false;
                    console.log("Does not meet dimensionality");
            }

            /* Filter by isReversible */

            if (filterModel.isReversible.length != 0
                && !(filterModel.isReversible.find(element => element == system.reversibility))) {
                shouldBeDisplayed = false;
                console.log("Does not meet reversibility");
            }

            /* Filter by actuation */

            if (filterModel.actuation.length != 0
                && !(filterModel.actuation.find(element => element == system.actuation))) {
                shouldBeDisplayed = false;
                console.log("Does not meet actuation");
            }

            /* Filter by isCombinationPhysicalVirtual */

            if (filterModel.isCombinationPhysicalVirtual.length != 0
                && !(filterModel.isCombinationPhysicalVirtual.find(element => element == system.virtualPhysical))) {
                shouldBeDisplayed = false;
                console.log("Does not meet combination");
            }

            /* Filter by surfaceResolution */

            if (filterModel.surfaceResolution.length != 0
                && !(filterModel.surfaceResolution.find(element => element == system.surfaceResolution))) {
                shouldBeDisplayed = false;
                console.log("Does not meet surface & resolution");
            }

            /* Filter by hierarchy */
            if (filterModel.hierarchy.length != 0
                && !(filterModel.hierarchy.find(element => element == system.hierarchy))) {
                shouldBeDisplayed = false;
                console.log("Does not meet hierarchy");
            }

            /* Filter by isMultistable */

            if (filterModel.isMultistable && system.multistable == 0) {
                shouldBeDisplayed = false;
                console.log("Does not meet multistable");
            }

            /* Filter by isInteractive */

            if (filterModel.isInteractive.length != 0
                && !(filterModel.isInteractive.find(element => element == system.interactivity))) {
                shouldBeDisplayed = false;
                console.log("Does not meet interactivity");
            }

            /* Filter by isSafe */

            if (filterModel.isSafe && system.safety == 0) {
                shouldBeDisplayed = false;
                console.log("Does not meet safety");
            }

            /* Filter by isSpaceConstrained */

            if (filterModel.isSpaceConstrained && system.space == 0) {
                shouldBeDisplayed = false;
                console.log("Does not meet space constrained");
            }

            if (shouldBeDisplayed)
                temp_filteredSystems.push(system);
        });

        this.filteredSystems = temp_filteredSystems;
    }


}

export default SystemsListModel;
