import React from 'react';
import './Switch.css';

class Switch extends React.Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange () {
        this.props.onChange();
    }

    render() {
        return (
          <div className={"SwitchWrapper "+ (this.props.checked ? "checked" : "")} onClick={this.onChange}>
              <div className="SwitchHandle" />
          </div>
        );
    }
}

export default Switch;