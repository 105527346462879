import React from 'react';
import './FilterArchiReconf.css';
import '../../../../Font.css';
import '../../../../icones.css';
import Switch from "../../../Utils/Switch/Switch";
import Checkbox from "../../../Utils/Checkbox/Checkbox";
import FilterModel from "../../../../Models/FilterModel";

class FilterArchiReconf extends React.Component {

    constructor(props) {
        super(props);

    }


    render() {

        return (
            <div className="FilterMicroWrapper">

                <div className="FormFilter">

                    <div className={"FilterFormElement "+ (this.props.architecture != undefined && this.props.architecture.length != 0 ? "highlighted" : "") }>
                        <div className="formObjectTitle">Architecture</div>
                        <div className="FormAnswersWrapper">
                            <Checkbox value="Lattice"
                                      checked={this.props.architecture.find(element => element == 'lattice')}
                                      onChange={this.props.updateArchitecture} />

                            <Checkbox value="Chain"
                                      checked={this.props.architecture.find(element => element == 'chain')}
                                      onChange={this.props.updateArchitecture} />

                            <Checkbox value="Swarm"
                                      checked={this.props.architecture.find(element => element == 'swarm')}
                                      onChange={this.props.updateArchitecture} />

                            <Checkbox value="Mobile"
                                      checked={this.props.architecture.find(element => element == 'mobile')}
                                      onChange={this.props.updateArchitecture} />

                            <Checkbox value="Hybrid"
                                      checked={this.props.architecture.find(element => element == 'hybrid')}
                                      onChange={this.props.updateArchitecture} />
                        </div>
                    </div>

                    <div className={"FilterFormElement "+ (this.props.reconfiguration != undefined && this.props.reconfiguration.length != 0 ? "highlighted" : "") }>
                        <div className="formObjectTitle">Reconfiguration type</div>
                        <div className="FormAnswersWrapper">
                            <Checkbox value="Self-reconfiguration"
                                      checked={this.props.reconfiguration.find(element => element == '1')}
                                      onChange={this.props.updateReconfiguration} />

                            <Checkbox value="Self-assembly"
                                      checked={this.props.reconfiguration.find(element => element == '2')}
                                      onChange={this.props.updateReconfiguration} />

                            <Checkbox value="Self-disassembly"
                                      checked={this.props.reconfiguration.find(element => element == '3')}
                                      onChange={this.props.updateReconfiguration} />

                            <Checkbox value="Self-repair"
                                      checked={this.props.reconfiguration.find(element => element == '4')}
                                      onChange={this.props.updateReconfiguration} />

                            <Checkbox value="Self-duplicate"
                                      checked={this.props.reconfiguration.find(element => element == '5')}
                                      onChange={this.props.updateReconfiguration} />
                        </div>
                    </div>
                </div>
            </div>

        );
    }

}

export default FilterArchiReconf;
