import React from 'react';
import './Describe.css';
import '../../../Font.css';
import '../../../icones.css';

class Describe extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {

        return (
            <div className="DescribeWrapper">

                <div className="FilterModaleWrapper">
                    <div className="backgroundModale" onClick={this.props.toggleDescribe} />

                    <div className="ContentModaleWrapper">

                        <div className="modaleDescriptionHeader">
                            <div className="closeBtnModaleWrapper">
                                <div className="btnCrossHover" onClick={this.props.toggleDescribe}/>
                                <div className="btnCross"/>
                            </div>
                            <div className="modaleTitle">Description: {this.props.system.name}</div>
                        </div>

                        <div className="FormDescribeCompareFilter">

                            <div className="SectionTitle">Architecture and Reconfiguration</div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Architecture</div>
                                <div className="formTitleSeparator"/>
                                <div className="DescribeCompareObjectValue">{this.props.system.architecture}</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Reconfiguration</div>

                                <div className="DescribeCompareObjectValue">{this.props.system.reconfiguration}</div>
                            </div>

                            <div className="SectionTitle">Properties at the scale of the modules (micro)</div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Input/Output</div>

                                <div className="DescribeCompareObjectValue">{this.props.system.IO}</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Processing</div>

                                <div className="DescribeCompareObjectValue">{this.props.system.processing}</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Size</div>

                                <div className="DescribeCompareObjectValue">{this.props.system.size}</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Shape</div>

                                <div className="DescribeCompareObjectValue">{this.props.system.shape}</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Speed</div>

                                <div className="DescribeCompareObjectValue">{this.props.system.speed}</div>
                            </div>


                            <div className={"DescribeCompareFormElement Packing" }>
                                <div className="ShapeChangeFeaturesWrapper">
                                    <div className="DescribeCompareObjectTitle">Packing</div>
                                    <div className="DescribeCompareObjectTitle Feature">Structure</div>
                                    <div className="DescribeCompareObjectTitle Feature">Density</div>

                                </div>

                                <div className="ShapeChangeFeaturesWrapper">
                                    <div className="void20px" />
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.structure}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.density}</div>
                                </div>
                            </div>


                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Strength</div>

                                <div className="DescribeCompareObjectValue">{this.props.system.strength}</div>
                            </div>




                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Power storage</div>

                                <div className="DescribeCompareObjectValue">{this.props.system.powerStorage}</div>
                            </div>

                            <div className="SectionTitle">Properties at the scale of the interface (macro)</div>

                            <div className={"DescribeCompareFormElement ShapeChange" }>
                                <div className="ShapeChangeFeaturesWrapper">
                                    <div className="DescribeCompareObjectTitle">Shape-change ability</div>
                                    <div className="DescribeCompareObjectTitle Feature">Amplitude</div>
                                    <div className="DescribeCompareObjectTitle Feature">Zero-crossing</div>
                                    <div className="DescribeCompareObjectTitle Feature">Closure</div>
                                    <div className="DescribeCompareObjectTitle Feature">Curvature</div>
                                    <div className="DescribeCompareObjectTitle Feature">Speed</div>
                                    <div className="DescribeCompareObjectTitle Feature">Modularity</div>
                                    <div className="DescribeCompareObjectTitle Feature">Porosity</div>
                                    <div className="DescribeCompareObjectTitle Feature">Stretchability</div>
                                    <div className="DescribeCompareObjectTitle Feature">Granularity</div>
                                    <div className="DescribeCompareObjectTitle Feature">Strength</div>
                                    <div className="DescribeCompareObjectTitle Feature">Size: Length</div>
                                    <div className="DescribeCompareObjectTitle Feature">Size: Area</div>
                                    <div className="DescribeCompareObjectTitle Feature">Size: Volume</div>

                                </div>

                                <div className="ShapeChangeFeaturesWrapper">
                                    <div className="void20px" />
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.shapechange_Amplitude}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.shapechange_ZeroCrossing}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.shapechange_Closure}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.shapechange_Curvature}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.shapechange_Speed}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.shapechange_Modularity}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.shapechange_Porosity}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.shapechange_Stretchability}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.shapechange_Granularity}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.shapechange_Strength}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.shapechange_SizeLength}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.shapechange_SizeArea}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.shapechange_SizeVolume}</div>
                                </div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Reversibility</div>

                                <div className="DescribeCompareObjectValue">{this.props.system.reversibility}</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Dimensionality</div>

                                <div className="DescribeCompareObjectValue">{this.props.system.dimensionality}</div>
                            </div>

                            <div className={"DescribeCompareFormElement Packing" }>
                                <div className="ShapeChangeFeaturesWrapper">
                                    <div className="DescribeCompareObjectTitle">Interactivity</div>
                                    <div className="DescribeCompareObjectTitle Feature">Input</div>
                                    <div className="DescribeCompareObjectTitle Feature">Output</div>

                                </div>

                                <div className="ShapeChangeFeaturesWrapper">
                                    <div className="void20px" />
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.interactivity_input}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.interactivity_output}</div>
                                </div>
                            </div>



                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Control over shape-change</div>

                                <div className="DescribeCompareObjectValue">{this.props.system.control}</div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Volume required for shape-change</div>

                                <div className="DescribeCompareObjectValue">{this.props.system.volume}</div>
                            </div>


                            <div className={"DescribeCompareFormElement Safety" }>
                                <div className="ShapeChangeFeaturesWrapper">
                                    <div className="DescribeCompareObjectTitle">Safety</div>
                                    <div className="DescribeCompareObjectTitle Feature">Severity</div>
                                    <div className="DescribeCompareObjectTitle Feature">Possibility of Avoidance</div>
                                    <div className="DescribeCompareObjectTitle Feature">Redundancy</div>

                                </div>

                                <div className="ShapeChangeFeaturesWrapper">
                                    <div className="void20px" />
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.safety_Severity}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.safety_Avoidance}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.safety_Redundancy}</div>
                                </div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Combination b/w representations</div>

                                <div className="DescribeCompareObjectValue">{this.props.system.combination}</div>
                            </div>

                            <div className={"DescribeCompareFormElement Safety" }>
                                <div className="ShapeChangeFeaturesWrapper">
                                    <div className="DescribeCompareObjectTitle">Coupling between modules</div>
                                    <div className="DescribeCompareObjectTitle Feature">Hierarchy</div>
                                    <div className="DescribeCompareObjectTitle Feature">Resolution</div>
                                    <div className="DescribeCompareObjectTitle Feature">Smoothness</div>

                                </div>

                                <div className="ShapeChangeFeaturesWrapper">
                                    <div className="void20px" />
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.hierarchy}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.resolution}</div>
                                    <div className="DescribeCompareObjectValue Feature">{this.props.system.smoothness}</div>
                                </div>
                            </div>

                            <div className={"DescribeCompareFormElement" }>
                                <div className="DescribeCompareObjectTitle">Usage consumption</div>

                                <div className="DescribeCompareObjectValue">{this.props.system.usageConsumption}</div>
                            </div>

                        </div>


                    </div>
                </div>

            </div>

        );
    }

}

export default Describe;
