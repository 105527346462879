import React from 'react';
import './FilterObject.css';
import '../../../../Font.css';
import '../../../../icones.css';

class FilterObject extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.deleteFilter = this.deleteFilter.bind(this);
    }

    deleteFilter() {
        //this.props.deleteFilter(this.props.filter.id);
    }

    render() {

        let dropdownFilter = "";

        return (
            <div className="FilterObjectWrapper">
                <div className="filterTitle">{this.props.name}</div>
                <div className="btnEditWrapper">
                    <div className="btnEdit" />
                    <div className="btnEditHover" onClick={this.props.toggleModaleActive} />
                </div> <div className="btnDeleteWrapper">
                    <div className="btnDelete" />
                    <div className="btnDeleteHover" onClick={() => this.props.deleteFilter(this.props.name)} />
                </div>
                {dropdownFilter}
            </div>
        );
    }

}

export default FilterObject;
