import React from 'react';
import './FilterMicro.css';
import '../../../../Font.css';
import '../../../../icones.css';
import Switch from "../../../Utils/Switch/Switch";
import Checkbox from "../../../Utils/Checkbox/Checkbox";

class FilterMicro extends React.Component {

    constructor(props) {
        super(props);

    }


    render() {

        return (
            <div className="FilterMicroWrapper">

                <div className="FormFilter">

                    <div className={"FilterFormElement "+ (this.props.shape != undefined && this.props.shape.length != 0 ? "highlighted" : "") }>
                        <div className="formObjectTitle">Shape</div>
                        <div className="FormAnswersWrapper">
                            <Checkbox value="Cube"
                                      checked={this.props.shape.find(element => element == '1')}
                                      onChange={this.props.updateShape} />

                            <Checkbox value="Quasi-sphere"
                                      checked={this.props.shape.find(element => element == '2')}
                                      onChange={this.props.updateShape} />

                            <Checkbox value="Cylinder"
                                      checked={this.props.shape.find(element => element == '3')}
                                      onChange={this.props.updateShape} />

                            <Checkbox value="Other"
                                      checked={this.props.shape.find(element => element == '4')}
                                      onChange={this.props.updateShape} />
                        </div>
                    </div>

                </div>
            </div>

        );
    }

}

export default FilterMicro;
