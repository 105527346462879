import React from 'react';
import '../../../Font.css';
import './PageRequirements.css';
import ObjectRequirement from "../ObjectRequirement/ObjectRequirement";

class PageRequirements extends React.Component {

    constructor(props) {

        super(props);
         const macroProperties = [
             new Requirement(1, "Digital", 'Hierarchy', 'Hierarchy defines whether the modules are standalones (i.e., driving the action by themselves) or satellites (i.e., the action is dependent on a standalone module or group of standalone modules). Satellites can be either original satellites (i.e., always synchronized to the same standalone module(s)) or borrowed satellites (i.e., able to synchronize to any standalone module(s)).'),
             new Requirement(2, "Dig./Physical",'Combination between states', 'A modular shape-changing UI is characterized through two states: (1) its physical state and (2) its digital state, also referred to as “computational representation”. The physical state can be described as the tangible object made of physical modules. The digital state can be described as the position and state of the modules in the computational model, due to the modules having to compute collectively in order to achieve a common goal. '),
             new Requirement(3, "Physical", 'Smoothness and resolution', 'Smoothness of the envelop is defined by deviations from the envelop, of the direction of the vector normal to the surface modules. We simplify this as the gaps between modules. Resolution of the envelop is defined as the input and output resolution at the surface of the UI, in dots per square centimeter (d cm−2).'),
             new Requirement(4, "Physical",'Shape-change ability', 'Shape-change ability quantifies how much the UI can change its shape. We characterize the shape- change ability of a system through the 11 Morphees+ features.'),
             new Requirement(5, "Physical",'Reversibility', 'Reversibility defines if the system is able to return to its initial state and repeat the shape- change.'),
             new Requirement(6, "Physical",'Dimensionality', 'Dimensionality describes whether the interface can reconfigure in 2D, 2.5D or 3D.'),
             new Requirement(7, "Physical",'Volume required for shape-change', 'The volume required for shape-change is the total volume occupied by the system throughout its change from the initial to the target shape.'),
             new Requirement(8,"Phys./Interaction", 'Usage consumption', 'The usage consumption is the power consumed under a standard context of use and the resulting ex- pected duration of the interaction.'),
             new Requirement(9, "Interaction",'Control over shape-change', 'Control over shape-change describes who controls the change of shape and how this control is shared between the user and the system. Existing shape-changing interfaces provide different levels of control over shape-change: direct, negotiated, indirect or system control.'),
             new Requirement(10, "Interaction",'Interactivity', 'Interactivity describes the modalities the system offers for the user to interact with it. The system can support user interaction, at least through shape-change and possibly through further input/output modalities.'),
             new Requirement(11, "Interaction",'Safety', 'Safety quantifies how much the system may endanger the user, bystanders or cause damage to physical property. A risk should be assessed through its severity, the possibility of avoidance and the redundancy.')
         ];

        const microProperties = [
            new Requirement(1, "Intra-module", 'Input, output and processing capabilities', 'Input, output and processing capabilities define how users interact with a single module. Input and output capabilities are dependent on micro-devices embedded in the module. '),
            new Requirement(2, "Intra-module", 'Power storage', 'Power storage defines how the modules are powered. Systems are either battery-powered or mains-operated.'),
            new Requirement(3, "Module", 'Size', 'The size is the volume of each module, defined by the length of the edge for cubic robots or the diameter for spherical robots.'),
            new Requirement(4, "Module", 'Shape', 'The shape defines the geometry of a module.'),
            new Requirement(5, "Module", 'Speed', 'The speed defines how fast a single module can move.'),
            new Requirement(6, "Inter-module", 'Density', 'Density defines if the structure is fully filled (high density) or not (lower density). The density is described by its number of units per volume.'),
            new Requirement(7, "Inter-module",'Structure', 'Structure defines the way of arranging modules so that they cover a volume without overlapping.'),
            new Requirement(8, "Inter-module",'Strength', 'The strength defines the force of the connection between a module and its neighbor(s). ')
        ];

         this.state = {
             macroProperties: macroProperties,
             microProperties: microProperties

         }

        this.handleNavigateTo = this.handleNavigateTo.bind(this);
    }

    handleNavigateTo(event) {
        this.props.navigateTo('2');
    }

    render() {

        return (
            <div className="PropertiesWrapper">

                <div className="PageTitle">Properties for the design of modular shape-changing user interfaces</div>
                <div className="TitleSeparator"></div>

                <div className="intro">
                    <p>Hi and welcome on the Molecular HCI Website! This page is a summary of the properties you can find in the <a className="link" href={'https://dl.acm.org/doi/10.1145/3461733'}>Molecular HCI paper</a>.</p>
                    <p>Feel free to take a look at the numerous <span className="link" onClick={this.handleNavigateTo}>existing systems</span> !</p>
                </div>

                <div className="PropertiesListWrapper">
                <div className="PropertiesList">
                    <div className="PropertiesListTitle"> Properties at the scale of the interface (macro) </div>
                    {this.state.macroProperties.map((macroProperty, i) => {
                        return (
                            <ObjectRequirement
                                key={macroProperty.idRequirement}
                                levelRequirement={macroProperty.levelRequirement}
                                titleRequirement={macroProperty.titleRequirement}
                                descriptionRequirement={macroProperty.descriptionRequirement}
                            />
                        )
                    })}
                </div>

                <div className="PropertiesList">
                    <div className="PropertiesListTitle"> Properties at the scale of the modules (micro) </div>
                    {this.state.microProperties.map((microProperty, i) => {
                        return (
                            <ObjectRequirement
                                key={microProperty.idRequirement}
                                levelRequirement={microProperty.levelRequirement}
                                titleRequirement={microProperty.titleRequirement}
                                descriptionRequirement={microProperty.descriptionRequirement}
                            />
                        )
                    })}
                </div>
                </div>
            </div>
        );
    }

}

export default PageRequirements;

class Requirement {

    constructor(idRequirement, levelRequirement, titleRequirement, descriptionRequirement) {
        this.idRequirement = idRequirement;
        this.levelRequirement = levelRequirement;
        this.titleRequirement = titleRequirement;
        this.descriptionRequirement = descriptionRequirement;
    }
}